var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',{attrs:{"flat":"","justify":"between","align":"center","color":"grey lighten-3 py-4"}},[_c('v-form',{ref:"form",attrs:{"justify":"center","align":"center","lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-container',{attrs:{"fluid":""}},[(false)?_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"12"}},[_c('v-card',[_vm._v(" model: "+_vm._s(_vm.model)+" ")])],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"12"}},[_c('v-card',[_vm._v(" resources: "+_vm._s(_vm.resources)+" ")])],1)],1):_vm._e(),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4","lg":"4"}},[_c('card-autocomplete',{attrs:{"items":_vm.resources.creative_template_id.items,"rules":_vm.resources.creative_template_id.rules,"loading":_vm.resources.creative_template_id.loading,"item_text":"name","item_value":"id","hint":`${_vm.$t(
							'CreativeBulkFile.fields.creative_template_id'
						)}`,"reference":`${_vm.$t(
							'CreativeBulkFile.fields.creative_template_id'
						)}`,"placeholder":`${_vm.$t(
							'CreativeBulkFile.fields.creative_template_id'
						)}`,"label":`${_vm.$t(
							'CreativeBulkFile.fields.creative_template_id'
						)}`,"multiple":false,"required":true,"colapse_selection":false,"hide_details":false},on:{"focus":function($event){return _vm.fetchResource('creative_template_id')}},model:{value:(_vm.model.creative_template_id),callback:function ($$v) {_vm.$set(_vm.model, "creative_template_id", $$v)},expression:"model.creative_template_id"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"2","lg":"1"}},[_c('card-switch',{attrs:{"reference":"secure","label":_vm.model.secure ? 'Secure' : 'Not Secure',"color":"success"},model:{value:(_vm.model.secure),callback:function ($$v) {_vm.$set(_vm.model, "secure", $$v)},expression:"model.secure"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4","lg":"4"}},[_c('card-text-field',{attrs:{"items":_vm.resources.name_prefix.items,"loading":_vm.resources.name_prefix.loading,"hint":`${_vm.$t(
							'CreativeBulkFile.fields.name_prefix'
						)}`,"reference":`${_vm.$t(
							'CreativeBulkFile.fields.name_prefix'
						)}`,"placeholder":`${_vm.$t(
							'CreativeBulkFile.fields.name_prefix'
						)}`,"label":`${_vm.$t(
							'CreativeBulkFile.fields.name_prefix'
						)}`,"required":false},model:{value:(_vm.model.name_prefix),callback:function ($$v) {_vm.$set(_vm.model, "name_prefix", $$v)},expression:"model.name_prefix"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"12"}},[_c('separator',{attrs:{"title":_vm.$t(
								'CreativeBulkFile.seccion.seccion_advertiser'
							)}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4","lg":"4"}},[_c('card-autocomplete',{attrs:{"items":_vm.resources.creative_advertiser.advertiser_id
								.items,"rules":_vm.resources.creative_advertiser.advertiser_id
								.rules,"loading":_vm.resources.creative_advertiser.advertiser_id
								.loading,"item_text":"value","item_value":"id","hint":`${_vm.$t(
							'CreativeBulkFile.fields.advertiser'
						)}`,"reference":`${_vm.$t(
							'CreativeBulkFile.fields.advertiser'
						)}`,"placeholder":`${_vm.$t(
							'CreativeBulkFile.fields.selected_advertiser'
						)}`,"label":`${_vm.$t(
							'CreativeBulkFile.fields.advertiser'
						)}`,"multiple":false,"required":true,"colapse_selection":false},on:{"focus":function($event){return _vm.fetchResource(
								'advertiser_id',
								'creative_advertiser'
							)}},model:{value:(_vm.model.creative_advertiser.advertiser_id),callback:function ($$v) {_vm.$set(_vm.model.creative_advertiser, "advertiser_id", $$v)},expression:"model.creative_advertiser.advertiser_id"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4","lg":"4"}},[_c('card-text-field',{attrs:{"items":_vm.resources.creative_advertiser.domain.items,"rules":_vm.resources.creative_advertiser.domain.rules,"loading":_vm.resources.creative_advertiser.domain.loading,"hint":`${_vm.$t(
							'CreativeBulkFile.fields.advertiser_domain'
						)}`,"reference":`${_vm.$t(
							'CreativeBulkFile.fields.advertiser_domain'
						)}`,"placeholder":`${_vm.$t(
							'CreativeBulkFile.fields.selected_domain'
						)}`,"label":`${_vm.$t(
							'CreativeBulkFile.fields.advertiser_domain'
						)}`,"required":true},model:{value:(_vm.model.creative_advertiser.domain),callback:function ($$v) {_vm.$set(_vm.model.creative_advertiser, "domain", $$v)},expression:"model.creative_advertiser.domain"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4","lg":"4"}},[_c('card-autocomplete',{attrs:{"items":_vm.resources.creative_advertiser.category_id.items,"rules":_vm.resources.creative_advertiser.category_id.rules,"loading":_vm.resources.creative_advertiser.category_id
								.loading,"item_text":"value","item_value":"id","hint":`${_vm.$t(
							'CreativeBulkFile.fields.advertiser_category'
						)}`,"reference":`${_vm.$t(
							'CreativeBulkFile.fields.advertiser_category'
						)}`,"placeholder":`${_vm.$t(
							'CreativeBulkFile.fields.selected_category'
						)}`,"label":`${_vm.$t(
							'CreativeBulkFile.fields.advertiser_category'
						)}`,"multiple":false,"colapse_selection":false,"required":true},model:{value:(_vm.model.creative_advertiser.category_id),callback:function ($$v) {_vm.$set(_vm.model.creative_advertiser, "category_id", $$v)},expression:"model.creative_advertiser.category_id"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"12"}},[_c('separator',{attrs:{"title":_vm.$t(
								'CreativeBulkFile.seccion.seccion_ad_content'
							)}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4","lg":"4"}},[(_vm.isJs)?_c('card-autocomplete',{attrs:{"items":_vm.resources.creative_ad_content.creative_rule_id
								.items,"rules":_vm.resources.creative_ad_content.creative_rule_id
								.rules,"loading":_vm.resources.creative_ad_content.creative_rule_id
								.loading,"item_text":"description","item_value":"id","hint":`${_vm.$t(
							'CreativeBulkFile.fields.creative_rule_id'
						)}`,"reference":`${_vm.$t(
							'CreativeBulkFile.fields.creative_rule_id'
						)}`,"placeholder":`${_vm.$t(
							'CreativeBulkFile.fields.creative_rule_id'
						)}`,"label":`${_vm.$t(
							'CreativeBulkFile.fields.creative_rule_id'
						)}`,"multiple":false,"required":true,"colapse_selection":false},on:{"focus":function($event){return _vm.fetchResource(
								'creative_rule_id',
								'creative_ad_content'
							)}},model:{value:(_vm.model.creative_ad_content.creative_rule_id),callback:function ($$v) {_vm.$set(_vm.model.creative_ad_content, "creative_rule_id", $$v)},expression:"model.creative_ad_content.creative_rule_id"}}):_vm._e(),(!_vm.isJs)?_c('card-text-field',{attrs:{"rules":_vm.resources.creative_ad_content.click_url.rules,"loading":_vm.resources.creative_ad_content.click_url.loading,"counter":2000,"hint":`${_vm.$t(
							'CreativeBulkFile.fields.ad_content_url'
						)}`,"reference":`${_vm.$t(
							'CreativeBulkFile.fields.ad_content_url'
						)}`,"placeholder":`${_vm.$t(
							'CreativeBulkFile.fields.selected_click_url'
						)}`,"label":`${_vm.$t(
							'CreativeBulkFile.fields.ad_content_url'
						)}`,"required":true,"error_messages":_vm.getError('creative_ad_content.click_url')},model:{value:(_vm.model.creative_ad_content.click_url),callback:function ($$v) {_vm.$set(_vm.model.creative_ad_content, "click_url", $$v)},expression:"model.creative_ad_content.click_url"}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"8","lg":"8"}},[_c('div',{staticClass:"d-flex mb-1"},[_c('span',{staticClass:"text-sm-left ml-3 text-label",class:{
								'error--text': !_vm.validFile,
								'black--text': _vm.validFile,
							}},[_vm._v(" "+_vm._s(_vm.$t("CreativeBulkFile.fields.Assets"))+" ")]),_c('span',{staticClass:"error--text ml-2"},[_c('strong',[_vm._v("*")])]),(_vm.isJs)?_c('upload-info',{attrs:{"dialog":_vm.openUploadInfo,"file":_vm.resources.file,"btnText":_vm.$t('privatepois.fields.file'),"customStyle":"z-index: 1090 !important;","dataType":"creative_bulk"}}):_vm._e()],1),_c('div',{staticClass:"d-flex flex-column align-center justify-center mx-3 py-3 borderBoxDialog",class:{
							'has-error': !_vm.validFile,
						},style:(_vm.stylesDrag),on:{"drop":function($event){$event.preventDefault();return _vm.dropHandler.apply(null, arguments)},"dragover":function($event){$event.preventDefault();return _vm.dragOverHandler.apply(null, arguments)},"dragleave":function($event){$event.preventDefault();return _vm.dragLeaveHandle.apply(null, arguments)}}},[_c('span',{staticClass:"text-sm-left font-weight-black",class:{
								'error--text': !_vm.validFile,
								'ext-color-dialog': _vm.validFile,
							}},[_vm._v(" "+_vm._s(this.$t("CreativeBulkFile.Drag"))+" ")]),_c('span',{staticClass:"text-sm-left font-weight-black",class:{
								'error--text': !_vm.validFile,
								'ext-color-dialog': _vm.validFile,
							}},[_vm._v(" "+_vm._s(this.$t("CreativeBulkFile.Or"))+" ")]),_c('div',{staticClass:"d-flex align-center"},[_c('v-file-input',{attrs:{"rules":_vm.resources.file.rules,"accept":_vm.acceptFile,"hide-input":true,"hide-details":true},model:{value:(_vm.model.file),callback:function ($$v) {_vm.$set(_vm.model, "file", $$v)},expression:"model.file"}}),_c('span',{staticClass:"text-sm-left font-weight-black mt-6",class:{
									'error--text': !_vm.validFile,
									'ext-color-dialog': _vm.validFile,
								}},[_vm._v(" "+_vm._s(this.$t("CreativeBulkFile.browser"))+" ")])],1)]),_c('div',{staticClass:"mx-3 mt-3"},[(!_vm.validFile)?_c('div',{staticClass:"v-text-field__details"},[_c('div',{staticClass:"v-messages theme--light error--text",attrs:{"role":"alert"}},[_c('div',{staticClass:"v-messages__wrapper"},[_c('div',{staticClass:"v-messages__message"},[_vm._v(" "+_vm._s(_vm.$t("fieldRequired"))+" ")])])])]):_vm._e(),_c('div',{staticClass:"v-text-field__details"},[_c('div',{staticClass:"v-messages theme--light error--text",attrs:{"role":"alert"}},[_c('div',{staticClass:"v-messages__wrapper"},[_c('div',{staticClass:"v-messages__message",class:{
											'error--text': !_vm.validFile,
											'black--text': _vm.validFile,
										}},[_vm._v(" "+_vm._s(_vm.getSupportedInfo)+" ")])])])])]),(_vm.existsFile)?_c('div',{staticClass:"d-flex"},[_c('v-card-text',{staticClass:"secondary--text",attrs:{"align":"start"}},[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v(" mdi-folder-zip-outline ")]),_vm._v(" "+_vm._s(_vm.getNameFile())+" "),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.removeFile()}}},[_c('v-icon',[_vm._v("mdi-close-circle")])],1)],1)],1):_vm._e()])],1),(!_vm.isJs)?_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"12"}},[_c('separator',{attrs:{"title":_vm.$t('CreativeBulkFile.seccion.seccion_exchange')}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4","lg":"4"}},[_c('card-text-field',{attrs:{"rules":_vm.resources.creative_exchange_options
								.landing_page_url.rules,"loading":_vm.resources.creative_exchange_options
								.landing_page_url.loading,"hint":`${_vm.$t(
							'CreativeBulkFile.fields.exchange_landing'
						)}`,"reference":`${_vm.$t(
							'CreativeBulkFile.fields.exchange_landing'
						)}`,"placeholder":`${_vm.$t(
							'CreativeBulkFile.fields.selected_landing_page'
						)}`,"label":`${_vm.$t(
							'CreativeBulkFile.fields.exchange_landing'
						)}`},model:{value:(
							_vm.model.creative_exchange_options.landing_page_url
						),callback:function ($$v) {_vm.$set(_vm.model.creative_exchange_options, "landing_page_url", $$v)},expression:"\n\t\t\t\t\t\t\tmodel.creative_exchange_options.landing_page_url\n\t\t\t\t\t\t"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4","lg":"4"}},[_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"text-sm-left ml-3"},[_vm._v(" "+_vm._s(_vm.$t("CreativeBulkFile.fields.exchange_nexus"))+" ")]),_c('v-checkbox',{staticClass:"ml-2",attrs:{"rules":_vm.resources.creative_exchange_options
									.appnexus_submit.rules,"loading":_vm.resources.creative_exchange_options
									.appnexus_submit.loading,"label":`${_vm.$t(
								'CreativeBulkFile.fields.description_nexus'
							)}`},model:{value:(
								_vm.model.creative_exchange_options
									.appnexus_submit
							),callback:function ($$v) {_vm.$set(_vm.model.creative_exchange_options
									, "appnexus_submit", $$v)},expression:"\n\t\t\t\t\t\t\t\tmodel.creative_exchange_options\n\t\t\t\t\t\t\t\t\t.appnexus_submit\n\t\t\t\t\t\t\t"}})],1)]),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"12"}},[_c('separator',{attrs:{"title":_vm.$t('separator.pixels_scripts')}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4","lg":"4"}},[_c('card-autocomplete',{attrs:{"items":_vm.resources.creative_addon_settings.addons.items,"rules":_vm.resources.creative_addon_settings.addons.rules,"loading":_vm.resources.creative_addon_settings.addons.loading,"item_text":"value","item_value":"id","hint":`${_vm.$t(
							'CreativeBulkFile.fields.creative_add_on'
						)}`,"reference":`${_vm.$t(
							'CreativeBulkFile.fields.creative_add_on'
						)}`,"placeholder":`${_vm.$t(
							'CreativeBulkFile.fields.selected_creative_add_ons'
						)}`,"label":`${_vm.$t(
							'CreativeBulkFile.fields.creative_add_on'
						)}`,"multiple":true,"colapse_selection":true},on:{"focus":function($event){return _vm.fetchResource(
								'addons',
								'creative_addon_settings'
							)}},model:{value:(_vm.model.creative_addon_settings.addons),callback:function ($$v) {_vm.$set(_vm.model.creative_addon_settings, "addons", $$v)},expression:"model.creative_addon_settings.addons"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4","lg":"4"}},[_c('card-text-field',{attrs:{"rules":_vm.resources.creative_addon_settings.pixels.rules,"loading":_vm.resources.creative_addon_settings.pixels.loading,"hint":"Para agregar presiona sobre el ícono ⊕","reference":"pixel","placeholder":`${_vm.$t(
							'CreativeBulkFile.fields.pixel_placeholder'
						)}`,"label":`${_vm.$t('CreativeBulkFile.fields.pixel')}`,"icon_add":true,"error":true,"counter":false,"persistent_hint":true},on:{"click-append":(event) => _vm.handleAppend(event, { key: 'pixel' })},model:{value:(_vm.model.creative_addon_settings.tmp.pixels),callback:function ($$v) {_vm.$set(_vm.model.creative_addon_settings.tmp, "pixels", $$v)},expression:"model.creative_addon_settings.tmp.pixels"}}),_c('array-list-item',{attrs:{"items":_vm.model.creative_addon_settings.pixels},on:{"delete-item":_vm.handleDeletePixel}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4","lg":"4"}},[_c('card-text-field',{attrs:{"rules":_vm.resources.creative_addon_settings.scripts.rules,"loading":_vm.resources.creative_addon_settings.scripts
								.loading,"reference":"script","hint":"Para agregar presiona sobre el ícono ⊕","placeholder":`${_vm.$t(
							'CreativeBulkFile.fields.script_placeholder'
						)}`,"label":`${_vm.$t('CreativeBulkFile.fields.script')}`,"persistent_hint":true,"icon_add":true,"error":true,"counter":false},on:{"click-append":(event) =>
								_vm.handleAppend(event, { key: 'script' })},model:{value:(_vm.model.creative_addon_settings.tmp.scripts),callback:function ($$v) {_vm.$set(_vm.model.creative_addon_settings.tmp, "scripts", $$v)},expression:"model.creative_addon_settings.tmp.scripts"}}),_c('array-list-item',{attrs:{"items":_vm.model.creative_addon_settings.scripts},on:{"delete-item":_vm.handleDeleteScript}})],1)],1):_vm._e(),_c('v-divider',{staticClass:"my-8"}),_c('v-row',{attrs:{"dense":"","align":"center","justify":"center"}},[_c('v-col',{staticClass:"pe-lg-16 pa-0",attrs:{"cols":"12","sm":"4","md":"4","lg":"6"}},[_c('v-card',{staticClass:"pe-lg-16 pa-0",attrs:{"elevation":"0","outlined":"","tile":"","color":"transparent"}},[_c('v-card-text',{staticClass:"secondary--text info-message",attrs:{"align":"start"}},[_vm._v(" * Mandatory fields ")])],1)],1),_c('v-col',{staticClass:"pe-lg-16 pa-0",attrs:{"cols":"12","sm":"8","md":"8","lg":"6"}},[_c('card-actions',{attrs:{"showSubmit":true,"showSave":false,"showSaveContinue":false},on:{"action":_vm.handleAction}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }